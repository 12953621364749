import React, { ReactNode, useState } from "react";
import * as Styles from "./Accordion.styles";
import {
  AccordionStyled,
  HeadingContainer,
  Heading,
  Content,
  AccordionIcon,
  AccordionColorTheme,
} from "./Accordion.styles";

type Props = {
  id?: string;
  /** The content of the component */
  children: ReactNode;
  heading: ReactNode;
  src?: ReactNode;
  /** Whether the component is initally open or not */
  readonly initiallyOpen?: boolean;
  colorTheme?: AccordionColorTheme;
  className?: string;
  /* starting position of the arrow */
  arrowDirection?: "vertical" | "horizontal";
};

/**
  Accordion is a component for displaying collapsible content panels for presenting information in a limited
  amount of space. Use an Accordion when the user needs the infromation only some of the time.
*/
const Accordion = (props: Props) => {
  const {
    id,
    heading,
    src,
    initiallyOpen = location?.hash?.length > 0 &&
      Boolean(id) &&
      location.hash.substring(1) === id,
    colorTheme = "blue",
    children,
    className,
    arrowDirection = "horizontal",
  } = props;

  const [open, setOpen] = useState(initiallyOpen);

  const toggle = (): void => {
    setOpen(!open);
  };
  const name = src || heading;
  return (
    <AccordionStyled id={id} color={colorTheme} className={className}>
      <HeadingContainer
        name={name !== undefined ? name.toString() : undefined}
        onClick={toggle}
      >
        <Heading>{src || heading}</Heading>
        <AccordionIcon
          name="direction_right"
          $open={open}
          $direction={arrowDirection}
        />
      </HeadingContainer>
      <Content $open={open}>{children}</Content>
    </AccordionStyled>
  );
};

export default Accordion;
export { Styles };
