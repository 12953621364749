import { DefaultTheme } from "styled-components";
import { lighten } from "polished";
import { primaryColor, colors } from "../styles/variables";
import baseTheme from "./baseTheme";
import merge from "ts-deepmerge";

const schwabingDarkGreen = "#085B22";
const schwabingGreen = "#3fa83c";
const { ghost, secondary } = baseTheme.buttons;

const strahlentherapieTheme: DefaultTheme = merge(baseTheme, {
  dir: "ltr",
  coverPosition: "30%",
  buttons: {
    borderRadius: 6,
    hover: schwabingDarkGreen,
    attachmentText: schwabingDarkGreen,
    primary: {
      text: "#fff",
      background: schwabingGreen,
      hoverBackground: lighten(0.1, schwabingGreen),
    },
    secondary,
    ghost,
    accent: {
      background: colors.transparent,
      text: primaryColor,
      border: primaryColor,
      hoverBackground: colors.transparent,
      hoverBorder: colors.gray950,
    },
    danger: {
      background: colors.red700,
      text: colors.white,
      border: colors.red700,
      hoverBackground: colors.red900,
      hoverBorder: colors.red900,
    },
  },
});

export default strahlentherapieTheme;
