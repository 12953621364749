import { get } from "lodash";

import baseTheme from "./baseTheme";
import aaPnhTheme from "./aaPnhTheme";
import accessCareTheme from "./accessCareTheme";
import ahusTheme from "./ahusTheme";
import chuvTheme from "./chuvTheme";
import demoOrigamaLiveTheme from "./demoOrigamaLiveTheme";
import demoOrigamaStagingTheme from "./demoOrigamaStagingTheme";
import demoOrigamaValidationTheme from "./demoOrigamaValidationTheme";
import dermaElbeklinikenTheme from "./dermaElbeklinikenTheme";
import docratesTheme from "./docratesTheme";
import eminervaTheme from "./eminervaTheme";
import eocTheme from "./eocTheme";
import ewellamoTheme from "./ewellamoTheme";
import heliosTheme from "./heliosTheme";
import hirslandenTheme from "./hirslandenTheme";
import hugTheme from "./hugTheme";
import ieoTheme from "./ieoTheme";
import istitutotumoriTheme from "./istitutotumoriTheme";
import krankenhausReinbekTheme from "./krankenhausReinbekTheme";
import mhHannoverTheme from "./mhHannoverTheme";
import nkiTheme from "./nkiTheme";
import nordlandssykehusetTheme from "./nordlandssykehusetTheme";
import ocsriTheme from "./ocsriTheme";
import parcdesalutmarTheme from "./parcdesalutmarTheme";
import promediTheme from "./promediTheme";
import stLoukasTheme from "./stLoukasTheme";
import stannaArsvivendiTheme from "./stannaArsvivendiTheme";
import stepTheme from "./stepTheme";
import strahlentherapieTheme from "./strahlentherapieTheme";
import ukEssenTheme from "./ukEssenTheme";
import rtlLangTheme from "./rtlLangTheme";

const themes = {
  "aa-pnh": aaPnhTheme,
  "access-care": accessCareTheme,
  ahus: ahusTheme,
  chuv: chuvTheme,
  "demo-origama-live": demoOrigamaLiveTheme,
  "demo-origama-staging": demoOrigamaStagingTheme,
  "demo-origama-validation": demoOrigamaValidationTheme,
  "derma-elbekliniken": dermaElbeklinikenTheme,
  docrates: docratesTheme,
  eminerva: eminervaTheme,
  eoc: eocTheme,
  ewellamo: ewellamoTheme,
  helios: heliosTheme,
  hirslanden: hirslandenTheme,
  hug: hugTheme,
  ieo: ieoTheme,
  istitutotumori: istitutotumoriTheme,
  "krankenhaus-reinbek": krankenhausReinbekTheme,
  "mh-hannover": mhHannoverTheme,
  nki: nkiTheme,
  nordlandssykehuset: nordlandssykehusetTheme,
  ocsri: ocsriTheme,
  parcdesalutmar: parcdesalutmarTheme,
  promedi: promediTheme,
  "st-loukas": stLoukasTheme,
  "stanna-arsvivendi": stannaArsvivendiTheme,
  step: stepTheme,
  strahlentherapie: strahlentherapieTheme,
  "uk-essen": ukEssenTheme,
};

const rtlList = [
  "ar",
  "arc",
  "dv",
  "fa",
  "ha",
  "he",
  "khw",
  "ks",
  "ku",
  "ps",
  "ur",
  "yi",
];

const getLangTheme = (locale: string) => {
  if (rtlList.includes(locale.split("-")[0])) {
    return rtlLangTheme;
  }
};

const getTheme = (
  site: string | undefined,
  theme_vars: Record<string, unknown> | undefined,
  locale: string | undefined,
) => {
  return {
    ...get(themes, site ?? "", baseTheme),
    ...(theme_vars ?? {}),
    ...(locale && getLangTheme(locale)),
  };
};

export default getTheme;
