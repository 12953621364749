import React from "react";
import * as Styles from "./Spinner.styles";
import { Container, Bounce1, Bounce2, CircleSpinner, CircleSpinnerSize } from "./Spinner.styles";
import { loadIndicatorHandle } from "@netmedi/frontend-semantic-handles";

/*
  Should the component leave ample extra space above the spinner. Set noPadding if
  the area that's loading is large.
*/
export interface ISpinner {
  noPadding?: boolean;
  color?: "white";
  circle?: boolean;
  circleColor?: string;
  circleSecondaryColor?: string;
  circleSize?: CircleSpinnerSize;
}

/**
  Use a Spinner to indicate the view or a major part of it is loading or performing an action.

  Don't use Spinner for very small areas of the UI. You can use the circle prop to render a smaller circle spinner.
*/
const Spinner = ({ noPadding, color, circleColor, circleSecondaryColor, circle, circleSize = "small" }: ISpinner) => {
  return circle ? (
    <CircleSpinner color={circleColor} secondaryColor={circleSecondaryColor} size={circleSize} />
  ) : (
    <Container noPadding={noPadding} color={color} {...loadIndicatorHandle()}>
      <Bounce1 />
      <Bounce2 />
      <div />
    </Container>
  );
};

export default Spinner;
export { Styles };
