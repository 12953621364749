import React from "react";
import * as Styles from "./Card.styles";
const { StyledCard, Container, IconContainer, HeadingContainer, Content } =
  Styles;

/** Card can be used to highlight, lift and group content.

You can use cards pretty much anywhere.
If you notice your card becoming very heavy on content,
consider using Panel or separating the content into multiple Cards.
*/
const Card = (props: Props) => {
  const {
    heading,
    children,
    noPadding = false,
    className,
    icon,
    type = "default",
    ...rest
  } = props;

  return (
    <StyledCard
      noPadding={noPadding}
      type={type}
      className={className}
      {...rest}
      data-testid={`card-${type}`}
    >
      {icon && <IconContainer>{icon}</IconContainer>}
      <Container>
        {!!heading && (
          <HeadingContainer>
            {React.isValidElement(heading) ? heading : <h3>{heading}</h3>}
          </HeadingContainer>
        )}
        {children && <Content heading={heading}>{children}</Content>}
      </Container>
    </StyledCard>
  );
};

export type CardType =
  | "white"
  | "neutral"
  | "alert"
  | "warning"
  | "success"
  | "default";

export type Props = {
  /** Content of the card */
  children?: React.ReactNode;
  /** Whether the component has padding or not */
  noPadding?: boolean;
  /** Optional heading for the component */
  heading?: React.ReactChild;
  /** Additional classNames */
  className?: string;
  /** Use optional pre-defined styles for Card */
  type?: CardType;
  id?: string;
  /** Attach Icon-component to Card */
  icon?: React.ReactElement;
};

export default Card;
export { Styles };
