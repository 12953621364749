// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from "react";
import PropTypes from "prop-types";
import Spacing from "../Spacing";
import ColumnLayoutStyled from "./ColumnLayout.styles";
import { spacingProps } from "../../types/proptypes";
import { range } from "lodash";

// A component creating a layout with n amount of columns. Number of columns is determined by
// the children count of the component or by explicitly setting the columns prop.

const ColumnLayout = ({
  columns = "auto",
  children,
  spacingTopBottom,
  spacingSide,
  spacingMiddle,
  ...props
}) => (
  <ColumnLayoutStyled {...props}>
    {range(columnCount(columns, children)).map(i => (
      <Spacing
        spacing={spacingTopBottom}
        left={i === 0 ? spacingSide : spacingMiddle}
        right={isLastItem(i, columns, children) ? spacingSide : spacingMiddle}
        key={i}
      >
        <div key={i}>{React.Children.toArray(children)[i]}</div>
      </Spacing>
    ))}
  </ColumnLayoutStyled>
);

const columnCount = (columnsProp: string | number, children: Node) => {
  if (columnsProp === "auto") {
    return React.Children.toArray(children).length;
  }

  // Force return type
  return Number(columnsProp);
};

const isLastItem = (
  i: number,
  columnsProp: string | number,
  children: Node,
) => {
  return i === columnCount(columnsProp, children) - 1;
};

ColumnLayout.propTypes = {
  /** Number of columns */
  columns: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(["auto"] as const),
  ]),
  /** Elements to be added to the columns. Number of elements should equal the number of columns. */
  children: PropTypes.node,
  /** The spacing to be added to the top and bottom of the child elements. If no other spacing is given, all sides of elements will have this spacing size.  */
  spacingTopBottom: spacingProps,
  /** Spacing that is going to be on the outermost left and right sides of (all) the children */
  spacingSide: spacingProps,
  /** Spacing that is going to be used in the middle of the child elements. Notice that this spacing will be added to both of the child elements, so the actual space between the elements will be twice this size. */
  spacingMiddle: spacingProps,
};

export default ColumnLayout;
export { ColumnLayoutStyled as Styles };
