import React from "react";
import * as Styles from "./Heading.styles";
import { Separator } from "./Heading.styles";

export interface IProps {
  /** Tag defines the heading element */
  tag: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  /** Additional classNames */
  className?: string;
  /** The value visualized */
  hasSeparator?: boolean;
  /** Content */
  children?: string | JSX.Element | any[];
}

const Heading = (props: IProps) => {
  const { tag, hasSeparator = true, className, children } = props;

  const Element = tag;
  return (
    <Element className={className} data-testid="heading">
      {hasSeparator && (
        <Separator data-testid="heading-separator">{children}</Separator>
      )}
      {!hasSeparator && children}
    </Element>
  );
};

export default Heading;
export { Styles };
