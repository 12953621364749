import React from "react";
import { Field } from "react-final-form";
import {
  RadioErrors,
  RadioGroupContainer,
  RadioGroupInput,
  RadioGroupLabel,
  RadioGroupOption,
  RadioWrapper,
} from "./RadioGroup.styles";

export type RadioOption = {
  value: string;
  label: string;
  disabled: boolean;
};

export type RadioGroupProps = {
  name: string;
  options: RadioOption[];
  validate?: (value: string) => string | JSX.Element | undefined;
  showRequiredIndicator?: boolean;
  required?: boolean;
};

const RadioGroup: React.FC<RadioGroupProps> = ({ name, options, validate }) => {
  return (
    <RadioGroupContainer data-testid="RadioGroup">
      <Field name={name} validate={validate}>
        {({ input, meta }) => (
          <>
            {options.map(option => (
              <RadioGroupOption key={option.value}>
                <RadioWrapper>
                  <RadioGroupInput
                    {...input}
                    type="radio"
                    id={`${name}-${option.value}`}
                    value={option.value}
                    disabled={option.disabled}
                    checked={option.value === input.value}
                  />
                </RadioWrapper>
                <RadioGroupLabel htmlFor={`${name}-${option.value}`}>
                  {option.label}
                </RadioGroupLabel>
              </RadioGroupOption>
            ))}

            {meta.error && meta.touched && (
              <RadioErrors>
                <span>{meta.error}</span>
              </RadioErrors>
            )}
          </>
        )}
      </Field>
    </RadioGroupContainer>
  );
};

export default RadioGroup;
