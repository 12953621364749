import { DefaultTheme } from "styled-components";
import { lighten } from "polished";
import colors2019 from "../styles/blueColors2019";
import { colors, scaffolding } from "../styles/variables";
import merge from "ts-deepmerge";
import baseTheme from "./baseTheme";

const { blue500 } = colors2019;
const { ghost, secondary } = baseTheme.buttons;
const hugGreen = "#93CFA9";

const hugTheme: DefaultTheme = merge(baseTheme, {
  dir: "ltr",
  coverPosition: "0%",
  fontFamilyHeadings:
    '"Cooper Hewitt Medium", "Trebuchet MS", Helvetica, sans-serif',
  fontFamilySansSerif: '"Cooper Hewitt Book", Arial, Helvetica, sans-serif',
  buttons: {
    borderRadius: 6,
    hover: colors.gray950,
    attachmentText: blue500,
    primary: {
      text: scaffolding.textColor,
      background: hugGreen,
      hoverBackground: lighten(0.1, hugGreen),
    },
    secondary,
    ghost,
    accent: {
      background: colors.transparent,
      text: "#1f1f1f",
      border: blue500,
      hoverBackground: colors.transparent,
      hoverBorder: colors.gray950,
    },
    danger: {
      background: colors.red700,
      text: colors.white,
      border: colors.red700,
      hoverBackground: colors.red900,
      hoverBorder: colors.red900,
    },
  },
});

export default hugTheme;
