import React from "react";
import { Link, LinkProps } from "react-router-dom";
import { IconName } from "../Icon";
import * as Styles from "./Button.styles";
import { ButtonIconStyled, ButtonSpinnerContainer, ButtonStyled } from "./Button.styles";
import Spinner from "../Spinner";
import { colors } from "../../styles/variables";

/**
  Buttons are used for:

  - Performing an action (e.g. "Submit" button on a form)
  - Starting a new task (e.g. "Invite clients" button in the client list)
  - Moving between phases in a process (e.g. "Next" and "Previous" buttons in multi-page forms)

  Buttons should not be used for other navigation between views - use links instead.
*/
type ButtonType = "accent" | "primary" | "secondary" | "ghost" | "danger";

const Button = ({
  submit,
  children,
  className,
  type,
  size = "default",
  primary,
  icon,
  iconEdge = "left",
  loading=false,
  ...rest
}: ButtonProps) => {
  const spinnerColors = {
    primary: "white",
    secondary: "white",
    danger: "white",
    accent: colors.gray700,
    ghost: "white",
  };

  return (
    <ButtonStyled
      {...rest}
      type={submit ? "submit" : "button"}
      $isPrimaryButton={primary}
      $buttonType={type}
      size={size}
      className={className}
    >
      {icon && iconEdge === "left" && !loading && (
        <ButtonIconStyled name={icon} align={iconEdge} />
      )}
      {loading &&
        <ButtonSpinnerContainer>
          <Spinner
            circle
            circleSize={"medium"}
            circleColor={spinnerColors[type ?? "primary"]}
            circleSecondaryColor="transparent"
          />
        </ButtonSpinnerContainer>}
      
      {/* Only change visibility to keep the original button size when showing spinner */}
      <div style={{ visibility: loading ? "hidden" : "visible" }}>
        {children}
      </div>
      {icon && iconEdge === "right" && !loading && (
        <ButtonIconStyled name={icon} align={iconEdge} />
      )}
    </ButtonStyled>
  );
};

const LinkButton = ({
  holvikaari,
  children,
  className,
  type,
  size = "default",
  tagName,
  primary,
  icon,
  iconEdge = "left",
  to = "",
  role,
  ...rest
}: LinkButtonProps) => {
  const El = tagName || (holvikaari || rest?.href ? "a" : Link);

  return (
    <ButtonStyled
      as={El}
      role={role ?? "button"}
      $isPrimaryButton={primary}
      $buttonType={type}
      size={size}
      className={className}
      icon={icon}
      to={to}
      {...(rest as any)}
    >
      {icon && iconEdge === "left" && (
        <ButtonIconStyled name={icon} align={iconEdge} />
      )}
      {children}
      {icon && iconEdge === "right" && (
        <ButtonIconStyled name={icon} align={iconEdge} />
      )}
    </ButtonStyled>
  );
};

type ButtonProps = React.HTMLAttributes<HTMLButtonElement> & {
  children?: React.ReactNode;
  type?: ButtonType;
  size?: "small" | "default";
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  submit?: boolean;
  primary?: boolean;
  icon?: IconName;
  iconEdge?: "left" | "right";
  loading?: boolean;
  ref?: React.RefObject<HTMLButtonElement>;
};

type LinkButtonProps = Partial<Omit<LinkProps, "size">> & {
  holvikaari?: boolean;
  children?: React.ReactNode;
  type?: ButtonType;
  size?: "small" | "default";
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  submit?: boolean;
  tagName?: string;
  primary?: boolean;
  href?: string;
  icon?: IconName;
  iconEdge?: "left" | "right";
};

export default Button;
export { LinkButton };
export { Styles };
export type { ButtonType };
