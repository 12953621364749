import { DefaultTheme } from "styled-components";
import { lighten } from "polished";
import { colors, linkColor, primaryColor } from "../styles/variables";
import baseTheme from "./baseTheme";
import merge from "ts-deepmerge";

const wasserblau = "#1e7a9e";
const { ghost, secondary } = baseTheme.buttons;

const krankenhausReinbekTheme: DefaultTheme = merge(baseTheme, {
  dir: "ltr",
  coverPosition: "10%",
  buttons: {
    borderRadius: 6,
    hover: colors.gray950,
    attachmentText: linkColor,
    primary: {
      text: "#fff",
      background: wasserblau,
      hoverBackground: lighten(0.1, wasserblau),
    },
    secondary,
    ghost,
    accent: {
      background: colors.transparent,
      text: primaryColor,
      border: primaryColor,
      hoverBackground: colors.transparent,
      hoverBorder: colors.gray950,
    },
    danger: {
      background: colors.red700,
      text: colors.white,
      border: colors.red700,
      hoverBackground: colors.red900,
      hoverBorder: colors.red900,
    },
  },
});

export default krankenhausReinbekTheme;
