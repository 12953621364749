import { DefaultTheme } from "styled-components";
import {
  buttons,
  typography,
  scaffolding,
  forms,
  colors,
} from "../styles/variables";

const theme: DefaultTheme = {
  dir: "ltr",
  coverPosition: "center",
  buttons,
  typography,
  scaffolding,
  forms,
  colors,
};

export default theme;
